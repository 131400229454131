<template>
  <div class="user-info over-flow">
    <div class="user-info__left left">
      <img :src="userInfo.imgPath | avatarFilter" alt="">
    </div>
    <div class="user-info__right right">
      {{userInfo.name}}
    </div>
  </div>
</template>

<script>
export default {
  name: "UserInfo",
  data(){
    return {
      userInfo: JSON.parse(localStorage.getItem('login-info'))
    }
  },
  filters:{
    avatarFilter(a){
      if (a){
        return a;
      }else{
        const sex = JSON.parse(localStorage.getItem('login-info'))
        if (sex){
          if (parseInt(sex) === 1){
            return  require('../assets/image/icon_boy.png')
          }else {
            return  require('../assets/image/icon_girl.png')
          }
        }else{
          return require('../assets/image/icon_unkown.png')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-info {
  position: absolute;
  width: 250px;
  height: 86px;
  top: 30px;
  left: 30px;

  .user-info__left {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background-color: #974AFF;
    padding: 3px;
    position: absolute;
    z-index: 99;
    img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .user-info__right {
    width: 178px;
    height: 56px;
    line-height: 56px;
    font-size: 24px;
    color: #FFFFFF;
    background-color: #974AFF;
    border-radius: 0 28px 28px 0;
    position: absolute;
    right: 0;
    top: 14px;
    z-index: 9;
    font-family: Microsoft YaHei;
  }
}
</style>
