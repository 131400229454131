<template>
  <div class="index over-flow">
    <user-info></user-info>
    <div class="index__live" @click="$router.push('/live')">
      <div class="index__live-img"></div>
      <div class="index__live-text">直播课</div>
    </div>
    <div class="index__recording" @click="$router.push('/record')">
      <div class="index__recording-img"></div>
      <div class="index__recording-text">录播课</div>
    </div>
    <div class="index__task" @click="$router.push('/MyTask')">
      <div class="index__task-img"></div>
      <div class="index__task-text">我的作业</div>
    </div>
  </div>
</template>

<script>
import UserInfo from "@/components/UserInfo";
export default {
  name: "Index",
  components: {UserInfo},
  data(){
    return {

    }
  },
  created() {

  },
  methods:{

  },
  mounted() {



  }
}
</script>

<style lang="scss" scoped>
.index {
  height: 100%;
  background: url("../assets/image/bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .index__live, .index__recording,.index__task{
    position: absolute;
    width: 188.46px;
    height: 240px;
    top: 185px;
    cursor: pointer;
    &>:nth-child(1){
      width: 188.46px;
      height: 188.46px;
    }
    &>:nth-child(2){
      margin-top: 22px;
      font-size: 22px;
      color: #FFFFFF;
    }
  }
  .index__live{
    left: 147px;
    .index__live-img{
      background: url("../assets/image/ic_live.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .index__recording{
    right: 444px;
    .index__recording-img{
      background: url("../assets/image/ic_rec.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .index__task{
    right: 147px;
    .index__task-img{
      background: url("../assets/image/ic_zuoy@2x(1).png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
